<template>
    <PrintBarcode :printList="printList" v-model:showPrintVisible="showPrintVisible" />
    <a-modal
        v-model:visible="visible"
        title="条码打印"
        @cancel="handleCancel"
        :destroyOnClose="true"
        okText="导出打印"
        @ok="handleSubmitPrint"
        width="1200px"
    >
        <a-table
            :columns="columns"
            :data-source="tableData"
            :loading="loading"
            bordered
            size="small"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiCjFinishProduct } from "@/api";
import Select from "@/components/Select";
import PrintBarcode from "./printBarcode";
import { showMessage } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import { baseURL } from "@/utils/config";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "条码",
        dataIndex: "barcode",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "条码打印次数",
        dataIndex: "codePrintCount",
    },
    {
        title: "生产状态",
        dataIndex: "statusDesc",
    },
];

export default defineComponent({
    props: ["barcodePrintingId"],
    emits: ["eventUpdateOk", "update:barcodePrintingId"],
    components: {
        Select,
        DownOutlined,
        Upload,
        PrintBarcode,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            showPrintVisible: false,
            selectedRowKeys: [],
            selectedRows: [],
            tableData: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
            printList: [],
        });

        const handleCancel = () => {
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.printList = [];
            emit("update:barcodePrintingId", null);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    cjWorkOrderId: props.barcodePrintingId,
                    statusIn: ["COMPLETED", "PRODUCING"],
                },
            };
            let res = await apiCjFinishProduct(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
            state.printList = selectedRows;
        };

        const handleSubmitPrint = () => {
            if (!state.tableData.length) return showMessage("info", "暂无数据");
            let url = `${baseURL}/web/mes-cj-work-order-product/export-product-barcode-list-by-work-order-id/${props.barcodePrintingId}`;
            window.open(url);
        };

        watch(
            () => props.barcodePrintingId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getTabelData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onSelectChange,
            columns,
            handleSubmitPrint,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
</style>
