<template>
    <FinishProduct
        v-model:cjWorkOrderId="finishProductId"
        :disableData="tableData"
        @onSelect="onSelectFinishProduct"
    />
    <a-modal
        v-model:visible="visible"
        title="扫码完成"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :footer="null"
        @ok="handleSubmit"
        width="1200px"
    >
        <div class="btn-box">
            <a-input
                v-model:value="barCode"
                ref="inputRef"
                :onblur="() => (isFocus = false)"
                style="opacity: 0"
            />
            <a-button type="primary" v-show="!isFocus" @click="handleStartScan">开始扫码</a-button>
            <a-button v-show="isFocus" type="primary" class="stop-btn">结束扫码</a-button>
        </div>
        <a-table
            :columns="columns"
            :data-source="tableData"
            :loading="loading"
            bordered
            size="small"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, watchEffect } from "vue";
import { apiCjFinishProductComplete, apiCjFinishProductDetailByWorkordrIdAndBarCode } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import FinishProduct from "@/components/FinishProduct";
 
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "条码",
        dataIndex: "barcode",
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "生产状态",
        dataIndex: "statusDesc",
    },
];

export default defineComponent({
    props: ["scanCompleteId"],
    emits: ["eventUpdateOk", "update:scanCompleteId"],
    components: {
        Select,
        DownOutlined,
        Upload,
        FinishProduct,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            finishProductId: false,
            inputRef: null,
            isFocus: false,
            barCode: "",
            tableData: [],
        });

        const handleCancel = () => {
            state.visible = false;
            state.tableData = [];
            emit("update:scanCompleteId", null);
        };

        const onSelectFinishProduct = value => {
            state.tableData = [...state.tableData, ...value];
            handleSubmit(value[0]?.barcode);
        };

        const handleSubmit = async barcode => {
            let res = await apiCjFinishProductComplete({
                cjWorkOrderId: props.scanCompleteId,
                barCodes: [barcode],
            });
            state.barCode = "";
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getCjFinishProductDetail(barcode);
            }
        };

        const handleStartScan = () => {
            state.isFocus = true;
            state.inputRef.focus();
        };

        const getCjFinishProductDetail = async code => {
            let res = await apiCjFinishProductDetailByWorkordrIdAndBarCode(
                props?.scanCompleteId,
                code
            );
            state.barCode = "";
            if (res.status === "SUCCESS" && res?.data) {
                state.tableData = [res?.data, ...state.tableData];
            } else {
                showMessage("info", "该工单下未找到此产品");
            }
        };

        const antiShake = (val, time) => {
            return setTimeout(() => {
                if (val) handleSubmit(val);
            }, time);
        };

        watchEffect(onInvalidate => {
            const timer = antiShake(state.barCode, 500);
            onInvalidate(() => clearTimeout(timer));
        });

        watch(
            () => props.scanCompleteId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            columns,
            onSelectFinishProduct,
            handleSubmit,
            handleStartScan,
        };
    },
});
</script>

<style lang="less" scoped>
.btn-box {
    text-align: right;
    margin-bottom: 10px;
    display: flex;
}
.stop-btn {
    background: #67c23a;
    border-color: #67c23a;
}
</style>
