<template>
    <a-modal
        v-model:visible="visible"
        title="生产报工"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="850px"
    >
        <a-descriptions title="工单信息" :column="2">
            <a-descriptions-item label="工单号" :span="2">
                {{ workOrderInfo?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="批次号">
                {{ workOrderInfo?.productionBatchNo }}
            </a-descriptions-item>
            <a-descriptions-item label="生产班组">
                {{ workOrderInfo?.produceTeamName }}
            </a-descriptions-item>
            <a-descriptions-item label="班组长">
                {{ workOrderInfo?.produceTeamLeaderName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ workOrderInfo?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ workOrderInfo?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ workOrderInfo?.spec }}
            </a-descriptions-item>
        </a-descriptions>

        <a-descriptions title="报工操作"></a-descriptions>
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            :validateTrigger="['onBlur', 'change']"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="生产人员"
                name="attendances"
                :rules="[{ required: true, message: '请选择生产人员' }]"
            >
                <Select
                    v-model:value="formData.attendances"
                    :options="attendanceOptions"
                    labelKey="value"
                    valueKey="key"
                    mode="multiple"
                    :labelInValue="true"
                />
            </a-form-item>

            <a-form-item
                label="报工工序"
                name="cjWorkOrderStepId"
                :rules="[{ required: true, message: '请选择报工工序' }]"
            >
                <Select
                    v-model:value="formData.cjWorkOrderStepId"
                    :options="reportProcessOptinons"
                    labelKey="workOrderStepName"
                    valueKey="workOrderStepId"
                />
                <div class="report-process-desc" v-show="formData.cjWorkOrderStepId">
                    <div>
                        <div>
                            <span>任务数量：</span>
                            <span>
                                {{ workOrderInfo?.taskQuantity }}
                            </span>
                        </div>
                        <div>
                            <span>已报工数量</span>
                            <span>{{ curSelectProcess?.passedReportCount }}</span>
                        </div>
                        <!-- <div>
                            <span>审核中数量</span>
                            <span>{{ curSelectProcess?.waitAuditReportCount }}</span>
                        </div> -->
                    </div>
                </div>
            </a-form-item>

            <a-form-item
                label="完成数量"
                name="completedCount"
                :rules="[{ required: true, message: `请输入完成数量` }]"
            >
                <a-input-number
                    v-model:value="formData.completedCount"
                    :min="0"
                    :precision="0"
                    :max="comCompleteMax"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="合格数量" :rules="[{ required: true }]">
                <a-input :value="comQualifiedCount" :disabled="true" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="不合格数量"
                name="unqualifiedCount"
                :rules="[
                    { required: true, message: '请输入不合格数量' },
                    { validator: checkUnQualifiedCount },
                ]"
            >
                <a-input-number
                    v-model:value="formData.unqualifiedCount"
                    :min="0"
                    :max="comUnQualifiedCount"
                    :precision="0"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item
                label="生产日期"
                name="reportDate"
                :rules="[{ required: true, message: '请选择生产日期' }]"
            >
                <a-date-picker v-model:value="formData.reportDate" valueFormat="YYYY-MM-DD" />
            </a-form-item>

            <a-form-item
                label="生产时长"
                name="productTime"
                :rules="[
                    { required: true, message: '请选择生产时长' },
                    { validator: checkProductTime },
                ]"
            >
                <a-time-range-picker
                    v-model:value="formData.productTime"
                    format="HH:mm"
                    valueFormat="HH:mm"
                    :minuteStep="10"
                />
                <span style="margin-left: 8px" v-show="formData.productTime?.length">
                    {{ caluHours() }} / H
                </span>
            </a-form-item>

            <a-form-item label="生产总工时" name="productTime">
                <span style="margin-left: 8px">
                    {{ (caluHours() * formData.attendances?.length).toFixed(2) }} / H
                </span>
            </a-form-item>

            <a-form-item
                label="报工描述"
                name="description"
                :rules="[{ validator: checkSpaceAll }]"
            >
                <a-textarea
                    v-model:value="formData.description"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item
                label="异常情况"
                name="exception"
                :rules="[{ required: true, message: '请选择异常情况' }]"
            >
                <a-radio-group v-model:value="formData.exception">
                    <a-radio :value="true">是</a-radio>
                    <a-radio :value="false">否</a-radio>
                </a-radio-group>
            </a-form-item>

            <div v-show="formData.exception">
                <a-form-item
                    label="异常时长"
                    name="exceptionWorkingHours"
                    :rules="[
                        { required: formData.exception, message: '请输入异常时长' },
                        { validator: checkExceptionWorkingHours },
                    ]"
                >
                    <a-input-number
                        v-model:value="formData.exceptionWorkingHours"
                        :disabled="!formData.exception"
                        :min="0"
                        :precision="2"
                        style="width: 100%"
                    />
                </a-form-item>

                <a-form-item label="异常总工时" name="productTime">
                    <span style="margin-left: 8px">
                        {{
                            (formData.exceptionWorkingHours * formData.attendances?.length).toFixed(
                                2
                            )
                        }}
                        / H
                    </span>
                </a-form-item>

                <a-form-item
                    label="异常事件名称"
                    name="exceptionMatter"
                    :rules="[
                        { required: formData.exception, message: '请输入异常事件名称' },
                        { validator: checkSpaceAll },
                    ]"
                >
                    <a-input
                        v-model:value="formData.exceptionMatter"
                        :disabled="!formData.exception"
                        :maxlength="100"
                        showCount
                    />
                </a-form-item>

                <a-form-item
                    label="事件等级"
                    name="exceptionLevel"
                    :rules="[{ required: true, message: '请选择事件等级' }]"
                >
                    <Select
                        v-model:value="formData.exceptionLevel"
                        :options="eventLevelOptions"
                        labelKey="value"
                        valueKey="key"
                    />
                </a-form-item>

                <a-form-item
                    label="异常描述"
                    name="exceptionDescription"
                    :rules="[{ validator: checkSpaceAll }]"
                >
                    <a-textarea
                        v-model:value="formData.exceptionDescription"
                        placeholder="最多可输入800字"
                        :disabled="!formData.exception"
                        :rows="4"
                        :maxlength="800"
                    />
                </a-form-item>

                <a-form-item name="exceptionEventFileIds">
                    <template v-slot:label>
                        事件图片({{ formData.exceptionEventFileIds.length }}/12)
                    </template>
                    <Upload
                        businessType="mes_exc_report_event_pic"
                        @onSuccess="ids => (formData.exceptionEventFileIds = ids)"
                    />
                </a-form-item>

                <a-form-item
                    label="处理状态"
                    name="exceptionProcessStatus"
                    :rules="[{ required: formData.exception, message: '请选择处理状态' }]"
                >
                    <a-radio-group v-model:value="formData.exceptionProcessStatus">
                        <a-radio value="PROCESSED">已处理</a-radio>
                        <a-radio value="WAIT_PROCESSED">未处理</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item
                    label="处理方案"
                    name="exceptionProcessingScheme"
                    v-show="comExceptionProcessStatus"
                    :rules="[
                        {
                            required: comExceptionProcessStatus,
                            message: '请输入处理方案',
                        },
                        { validator: checkSpaceAll },
                    ]"
                >
                    <a-textarea
                        v-model:value="formData.exceptionProcessingScheme"
                        placeholder="最多可输入800字"
                        :disabled="!formData.exception"
                        :rows="4"
                        :maxlength="800"
                    />
                </a-form-item>

                <a-form-item name="exceptionEventHandleFileIds" v-show="comExceptionProcessStatus">
                    <template v-slot:label>
                        处理方案图片({{ formData.exceptionEventHandleFileIds.length }}/12)
                    </template>
                    <Upload
                        businessType="mes_exc_report_handle_pic"
                        @onSuccess="ids => (formData.exceptionEventHandleFileIds = ids)"
                    />
                </a-form-item>
            </div>

            <a-form-item :wrapper-col="{ offset: 18, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiCjWorkOrderGetReportInfo, apiCjWorkOrderSaveReport } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
import moment from "moment";
export default defineComponent({
    props: ["workReportId"],
    emits: ["eventUpdateOk", "update:workReportId"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const state = reactive({
            formRef: null,
            visible: false,
            workOrderInfo: null,
            attendanceOptions: [],
            reportProcessOptinons: [],
            orderListModalVisible: false,
            eventLevelOptions: [],
            formData: {
                cjWorkOrderStepId: "",
                attendances: [],
                completedCount: "",
                unqualifiedCount: "",
                productTime: [],
                description: "",
                reportDate: "",
                exception: false,
                exceptionWorkingHours: "",
                exceptionMatter: "",
                exceptionLevel: "GENERAL",
                exceptionDescription: "",
                exceptionEventFileIds: [],
                exceptionProcessStatus: null,
                exceptionProcessingScheme: "",
                exceptionEventHandleFileIds: [],
            },
            comCompleteMax: computed(() => {
                const { curSelectProcess, workOrderInfo } = state;
                if (curSelectProcess) {
                    const { passedReportCount, waitAuditReportCount } = curSelectProcess;
                    return workOrderInfo?.taskQuantity - passedReportCount - waitAuditReportCount;
                }
                return Infinity;
            }),
            comQualifiedCount: computed(() => {
                const { completedCount, unqualifiedCount } = state.formData;
                const num = unqualifiedCount ? completedCount - unqualifiedCount : "";
                return num < 0 ? 0 : num;
            }),
            comUnQualifiedCount: computed(() => {
                const { completedCount } = state.formData;
                return completedCount;
            }),
            curSelectProcess: computed(() => {
                state.formData.completedCount = "";
                state.formData.unqualifiedCount = "";
                let step = state.reportProcessOptinons.find(
                    item => item.workOrderStepId === state.formData.cjWorkOrderStepId
                );
                return step;
            }),
            comExceptionProcessStatus: computed(
                () => state.formData.exceptionProcessStatus == "PROCESSED"
            ),
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:workReportId", null);
        };

        const onFinish = async () => {
            let {
                reportDate,
                productTime: [productionStartTime, productionEndTime],
                attendances,
            } = state.formData;
            let reqData = {
                ...state.formData,
                attendances: attendances.map(item => ({
                    username: item?.option?.value,
                    displayName: item?.option?.label,
                })),
                productionStartTime: `${reportDate} ${productionStartTime}:00`,
                productionEndTime: `${reportDate} ${productionEndTime}:00`,
            };
            if (!reqData.exception) {
                reqData.exceptionWorkingHours = "";
                reqData.exceptionMatter = "";
                reqData.exceptionLevel = null;
                reqData.exceptionDescription = "";
                reqData.exceptionEventFileIds = [];
                reqData.exceptionProcessStatus = null;
                reqData.exceptionProcessingScheme = "";
                reqData.exceptionEventHandleFileIds = [];
            }
            let res = await apiCjWorkOrderSaveReport(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiCjWorkOrderGetReportInfo(id);
            if (res.status === "SUCCESS") {
                let {
                    mesCjWorkOrderInfo,
                    memberCandidate,
                    workOrderStepCandidate,
                    eventLevelCandidate,
                } = res.data;
                state.workOrderInfo = mesCjWorkOrderInfo;
                state.attendanceOptions = memberCandidate;
                state.reportProcessOptinons = workOrderStepCandidate;
                state.eventLevelOptions = eventLevelCandidate;
            }
        };

        const checkOrder = (rule, value) => {
            if (!value && !state.formData.relatedOrders) {
                return Promise.reject("请选择生产订单号");
            } else {
                return Promise.resolve();
            }
        };

        const checkExceptionWorkingHours = (rule, value) => {
            const { productTime, exception } = state.formData;
            if (productTime.length && exception) {
                const [sTime, eTime] = productTime;
                let totalMinutes = moment(eTime, "hh:mm").diff(moment(sTime, "hh:mm"), "minute");
                let exceptionHours = value * 60;
                if (exceptionHours > totalMinutes) {
                    return Promise.reject("异常时长不能大于生产时长");
                }
            }
            return Promise.resolve();
        };

        const checkUnQualifiedCount = (rule, value) => {
            const { completedCount, unQualifiedCount } = state.formData;
            if (unQualifiedCount > completedCount) {
                return Promise.reject("不合格数量不能大于完成数量");
            }
            return Promise.resolve();
        };

        const caluHours = () => {
            const { productTime } = state.formData;
            if (productTime?.length == 2) {
                const [sTime, eTime] = productTime;
                const during = moment(eTime, "hh:mm").diff(moment(sTime, "hh:mm"), "minute") / 60;
                return (Math.floor(during * 100) / 100).toFixed(2);
            }
            return "";
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const checkProductTime = (rule, value) => {
            if (value && value?.length) {
                if (value[0] == value[1]) return Promise.reject("生产时长不能为0");
            }
            return Promise.resolve();
        };

        watch(
            () => state.curSelectProcess,
            newVal => {
                if (newVal?.canReportSumSchedule == 0) {
                    showMessage("warning", "该工序已报工完成，不能再继续报工");
                }
            }
        );

        watch(
            () => props.workReportId,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(newVal);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceAll,
            checkOrder,
            checkExceptionWorkingHours,
            checkUnQualifiedCount,
            caluHours,
            checkSpaceAll,
            checkProductTime,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
</style>
