<template>
    <div class="container">
        <WorkReport v-model:workReportId="workReportId" @eventUpdateOk="getTabelData" />
        <DetailDrawer v-model:detailId="detailId" />
        <MaterialBinding v-model:materialBindingRow="materialBindingRow" />
        <BarcodePrinting v-model:barcodePrintingId="barcodePrintingId" />

        <ScanComplete v-model:scanCompleteId="scanCompleteId" />
        <a-tabs v-model:activeKey="status" @change="changeTabStatus">
            <a-tab-pane key="PRODUCING" tab="生产中工单"></a-tab-pane>
        </a-tabs>
        <FilterBlock
            @search="search"
            :produceTeamOptions="produceTeamOptions"
            :curStepCodeAndNameCandidate="curStepCodeAndNameCandidate"
        />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'orderCode'">
                        {{ record?.orderCode || "未关联订单号" }}
                    </template>
                    <template v-if="column.dataIndex === 'progressPercent'">
                        <a-progress :percent="record.progressPercent" size="small" />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click.prevent>菜单</a>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item
                                        v-for="(item, index) in operateMenus"
                                        :key="index"
                                        :v-perm="item.code"
                                    >
                                        <a @click="() => item.fn(record)">
                                            {{ item.title }}
                                        </a>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
    <a-modal
        :visible="Boolean(+terminatedId)"
        title="终止工单"
        @cancel="cancelSureTerminated"
        @ok="handleSureTerminated"
    >
        <p style="margin-bottom: 20px">
            工单终止后状态将不可变更，如确认终止，请在下方输入框输入“确认终止”并点 击提交。
        </p>
        <a-input v-model:value="sureText" />
    </a-modal>
</template>

<script>
import { defineComponent, reactive, toRefs, watch } from "vue";
import FilterBlock from "./filterBlock";
import WorkReport from "./workReport";
import DetailDrawer from "./detailDrawer";
import MaterialBinding from "./materialBinding";
import BarcodePrinting from "./barcodePrinting";
import ScanComplete from "./scanComplete";
import { useRouter } from "vue-router";
import { confirmModal, showMessage, jsonToUrlParams } from "@/utils/common";
import {
    apiCJWorkOrderIndexData,
    apiCJWorkOrderTerminated,
    apiCJWorkOrderList,
    apiCJWorkOrderCompleted,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        WorkReport,
        DetailDrawer,
        MaterialBinding,
        BarcodePrinting,
        ScanComplete,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "工单号",
                dataIndex: "code",
                fixed: "left",
            },
            {
                title: "生产订单号",
                dataIndex: "orderCode",
            },
            {
                title: "工单类型",
                dataIndex: "typeDesc",
            },
            {
                title: "项目名称",
                dataIndex: "projectName",
            },
            {
                title: "项目编码",
                dataIndex: "projectCode",
            },
            {
                title: "工单进度",
                dataIndex: "progressPercent",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "规格型号",
                dataIndex: "spec",
            },
            {
                title: "生产批次号",
                dataIndex: "productionBatchNo",
            },
            {
                title: "任务数量",
                dataIndex: "taskQuantity",
            },
            {
                title: "已绑定数量",
                dataIndex: "bindCount",
            },
            {
                title: "完成数量",
                dataIndex: "finishedProductCount",
            },
            {
                title: "单位",
                dataIndex: "taskQuantityUnit",
            },
            {
                title: "生产班组",
                dataIndex: "produceTeamName",
            },
            {
                title: "班组长",
                dataIndex: "produceTeamLeaderName",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 120,
            },
        ];

        const { push } = useRouter();

        const state = reactive({
            status: "PRODUCING",
            produceTeamOptions: [],
            curStepCodeAndNameCandidate: [],
            workReportId: null,
            detailId: null,
            materialBindingRow: null,
            barcodePrintingId: null,
            scanCompleteId: null,
            processflowId: null,
            params: null,
            data: [],
            loading: false,
            terminatedId: "",
            sureText: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
            operateMenus: [
                {
                    title: "详情",
                    fn: row => (state.detailId = row?.id),
                },
                {
                    title: "报工",
                    fn: row => (state.workReportId = row?.id),
                    code: "btn:mes-workshop-works-report-work",
                },
                // {
                //     title: "物料绑定",
                //     fn: row => (state.materialBindingRow = row),
                //     code: "btn:mes-workshop-works-material-bind",
                // },
                {
                    title: "工序作业",
                    fn: row => handleStepHomework(row),
                    code: "btn:mes-workshop-works-material-bind",
                },
                {
                    title: "条码打印",
                    fn: row => (state.barcodePrintingId = row?.id),
                    code: "btn:workshop-finished-product-mgr-print-barcode",
                },
                {
                    title: "扫码完成",
                    fn: row => (state.scanCompleteId = row?.id),
                    code: "btn:workshop-finished-product-mgr-complete",
                },
                {
                    title: "完成",
                    fn: row => handleComplete(row?.id),
                    code: "btn:mes-workshop-works-completed",
                },
                {
                    title: "终止",
                    fn: row => (state.terminatedId = row?.id),
                    code: "btn:mes-workshop-works-terminated",
                },
            ],
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiCJWorkOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        getTabelData();

        const changeTabStatus = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const cancelSureTerminated = () => {
            state.terminatedId = "";
            state.sureText = "";
        };

        const handleSureTerminated = async () => {
            if (state.sureText != "确认终止") {
                return showMessage("info", "请输入‘确认终止’以终止");
            }
            let res = await apiCJWorkOrderTerminated([state.terminatedId]);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getTabelData();
                cancelSureTerminated();
            }
        };

        const handleComplete = id => {
            confirmModal({
                type: "warning",
                content: "工单完成后状态将不可变更，是否确认完成？",
                onOk: async () => {
                    let res = await apiCJWorkOrderCompleted(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const getIndexData = async () => {
            let res = await apiCJWorkOrderIndexData("");
            if (res.status === "SUCCESS") {
                state.produceTeamOptions = res.data?.produceTeamCandidate;
                state.curStepCodeAndNameCandidate = res.data?.processCandidate;
            }
        };
        getIndexData();

        const handleStepHomework = row => {
            const { id, code, productName, productCode, taskQuantity, finishedProductCount } = row;
            push(
                `/product-manage/step-homework${jsonToUrlParams({
                    id,
                    code,
                    productName,
                    productCode,
                    taskQuantity,
                    finishedProductCount,
                })}`
            );
        };

        watch(
            () => [
                state.workReportId,
                state.materialBindingRow,
                state.barcodePrintingId,
                state.scanCompleteId,
            ],
            () => getTabelData()
        );

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            changeTabStatus,
            handleOpenDetail,
            handleComplete,
            getTabelData,
            handleSureTerminated,
            cancelSureTerminated,
            handleStepHomework,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
