<template>
    <div class="clearfix">
        <a-upload
            v-model:file-list="fileList"
            :action="`${baseURL}/web/mes-file-info/upload?businessType=${businessType}`"
            list-type="picture-card"
            accept="image/*"
            :maxCount="maxCount"
            :headers="headers"
            @preview="handlePreview"
            @change="onChange"
            @remove="onRemove"
            :before-upload="beforeUpload"
            :disabled="disabled"
        >
            <div v-if="fileList.length < maxCount">
                <plus-outlined />
            </div>
        </a-upload>
        <a-modal
            :visible="previewVisible"
            :title="previewTitle"
            :footer="null"
            @cancel="handleCancel"
        >
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script>
import { PlusOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { baseURL } from "@/utils/config";
import { useStore } from "vuex";
import { showMessage, confirmModal, getAutoCreateCode } from "@/utils/common";
import { Upload } from "ant-design-vue";

// 异常上报 事件图片
//     mes_exc_report_event_pic
// 异常上报 处理图片
//    mes_exc_report_handle_pic
// 报工, 现场图片对应的业务类型
//    REPORT_WORK_LIVE_PICTURE
// 工序完成操作 图片
//    work_order_step_complete_live_pic;
// 工序完成操作 检验员签名
//     work_order_step_complete_sign_pic;
// 工序返工操作 图片
//        work_order_step_rework_live_pic;
// 工序返工操作 检验员签名
//      work_order_step_rework_sugn_pic;
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export default defineComponent({
    components: {
        PlusOutlined,
    },
    props: {
        businessType: {
            default: "REPORT_WORK_LIVE_PICTURE",
        },
        maxCount: {
            default: 12,
        },
        defaultPics: {
            default: [],
        },
        disabled: {
            default: false,
        },
    },
    emits: ["onSuccess"],
    setup(props, { emit }) {
        const state = reactive({
            picIds: props?.defaultPics?.map(item => item.id),
        });
        const { getters } = useStore();
        const previewVisible = ref(false);
        const previewImage = ref("");
        const previewTitle = ref("");
        const fileList = ref(props?.defaultPics);

        const handleCancel = () => {
            previewVisible.value = false;
            previewTitle.value = "";
        };

        const handlePreview = async file => {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            previewImage.value = file.url || file.preview;
            previewVisible.value = true;
            previewTitle.value = file.name || file.url.substring(file.url.lastIndexOf("/") + 1);
        };

        const onChange = data => {
            if (data?.file?.status == "done") {
                state.picIds.push(data?.file.response?.data);
                emit("onSuccess", state.picIds);
            }
        };

        const beforeUpload = file => {
            if (file?.name?.length > 100) {
                showMessage("info", "文件名长度不能超过100");
                return Upload.LIST_IGNORE;
            }
            if (file.size / 1024 / 1024 > 20) {
                showMessage("info", "文件大小超出20M限制");
                return Upload.LIST_IGNORE;
            }
        };

        const onRemove = file => {
            const id = file?.id ? file?.id : file.response?.data;
            const index = state.picIds.indexOf(id);
            state.picIds.splice(index, 1);
            emit("onSuccess", state.picIds);
        };

        return {
            baseURL,
            ...toRefs(state),
            headers: {
                "mes-token": getters["user/token"],
                // "context-type": "multipart/form-data",
            },
            previewVisible,
            previewImage,
            fileList,
            handleCancel,
            handlePreview,
            previewTitle,
            onChange,
            onRemove,
            beforeUpload,
        };
    },
});
</script>
<style>
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>
