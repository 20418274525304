<template>
    <a-modal
        v-model:visible="visible"
        title="产成品列表"
        width="1000px"
        okText="确定"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <!-- <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.jobNo" placeholder="请输入工号" />
            <a-input v-model:value="params.displayName" placeholder="请输入姓名" />
            <a-button type="primary" @click="handleSearch">搜索</a-button>
        </a-space> -->

        <a-table
            :columns="columns"
            :data-source="tableData"
            :loading="loading"
            bordered
            size="small"
            :pagination="pagination"
            @change="paginationChange"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: 'radio',
                getCheckboxProps: record => ({
                    disabled: disableData.map(item => item.id).includes(record.id),
                }),
            }"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiCjFinishProduct } from "@/api";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "产品条码",
        dataIndex: "barcode",
    },
    {
        title: "生产批次号",
        dataIndex: "productionBatchNo",
    },
];

export default defineComponent({
    props: {
        cjWorkOrderId: {
            default: false,
        },
        selectType: {
            default: "checkbox", // radio
        },
        apiParams: {
            default: "",
        },
        disableData: {
            default: [],
        },
    },
    emits: ["update:cjWorkOrderId", "onSelect"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            name: "",
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
            params: {
                jobNo: "",
                displayName: "",
                deptId: "",
                enabled: true,
            },
        });

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:cjWorkOrderId", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                params: { jobNo, displayName, deptId },
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    cjWorkOrderId: props.cjWorkOrderId,
                    statusIn: ["COMPLETED"],
                },
            };
            let res = await apiCjFinishProduct(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = async () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择产成品");
            }
            emit("onSelect", state.selectedRows);
            handleCancel();
        };

        watch(
            () => props.cjWorkOrderId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.params.jobNo = "";
                    state.params.displayName = "";
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
            handleSearch,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}
</style>
