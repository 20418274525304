<template>
    <a-drawer
        v-model:visible="visible"
        :maskClosable="false"
        title="物料绑定"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">提交</a-button>
            </a-space>
        </template>
        <a-descriptions title="工单信息">
            <a-descriptions-item label="工单号">
                {{ materialBindingRow?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="批次号">
                {{ materialBindingRow?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="操作人">
                {{ materialBindingRow?.creatorDisplayName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品名称">
                {{ materialBindingRow?.productName }}
            </a-descriptions-item>
            <a-descriptions-item label="产品编码">
                {{ materialBindingRow?.productCode }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ materialBindingRow?.spec }}
            </a-descriptions-item>
            <a-descriptions-item label="任务数量">
                {{ materialBindingRow?.taskQuantity }}
            </a-descriptions-item>
            <a-descriptions-item label="该工序已绑定">
                {{ materialBindingRow?.bindCount }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions title="工序操作"></a-descriptions>

        <a-form ref="formRef" :model="formData" name="basic" autocomplete="off">
            <a-row :span="24" style="margin-bottom: 15px">
                <a-col :span="12">
                    <a-form-item
                        label="工序选择"
                        name="cjWorkOrderStepId"
                        :rules="[{ required: true, message: '请选择工序' }]"
                    >
                        <Select
                            v-model:value="formData.cjWorkOrderStepId"
                            :options="processOptions"
                            labelKey="name"
                            valueKey="id"
                            style="width: 80%"
                        />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item
                        label="产成品条码"
                        name="cjProductBarcode"
                        :rules="[{ required: true, message: '请选择产成品条码' }]"
                    >
                        <a-form-item>
                            <a-input
                                :value="formData.cjProductBarcode"
                                :disabled="true"
                                style="width: 80%"
                            />
                            <a-input
                                ref="productInputRef"
                                v-model:value="formData.cjProductBarcode"
                                @blur="productInputOnblur = false"
                                class="hideinput"
                            />
                        </a-form-item>
                        <a-button
                            type="primary"
                            v-show="!productInputOnblur"
                            @click="handleScanProduct"
                            class="btn"
                        >
                            开始扫码
                        </a-button>
                        <a-button v-show="productInputOnblur" type="primary" class="btn stop">
                            结束扫码
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
            <ProductTable
                :materialBindingRow="materialBindingRow"
                :cjProductBarcode="formData.cjProductBarcode"
                @delete="handleDeleteFinishProductTableData"
                @succcess="() => productInputRef.blur()"
            />
            <a-row :span="24" style="margin-bottom: 15px">
                <a-col :span="12">
                    <a-form-item
                        label="物料条码"
                        name="password"
                        :rules="[{ required: true, message: 'Please input your password!' }]"
                    >
                        <a-form-item>
                            <a-input
                                :value="formData.materialBarCode"
                                :disabled="true"
                                style="width: 80%"
                            />
                            <a-input
                                ref="materialInputRef"
                                v-model:value="formData.materialBarCode"
                                @blur="materialInputOnblur = false"
                                class="hideinput material"
                            />
                        </a-form-item>
                        <a-button
                            type="primary"
                            v-show="!materialInputOnblur"
                            @click="handleScanMaterial"
                            class="btn material"
                        >
                            开始扫码
                        </a-button>
                        <a-button v-show="materialInputOnblur" type="primary" class="btn stop">
                            结束扫码
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
        <MaterialTable
            :materialBindingRow="materialBindingRow"
            v-model:materialBarCode="formData.materialBarCode"
            @changeMaterialTable="onChangeMaterialTable"
            @succcess="() => (formData.materialBarCode = '')"
        />
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, watchEffect } from "vue";
import { apiCjWorkOrderMaterialBindIndexData, apiSaveMaterial } from "@/api";
import { showMessage } from "@/utils/common";
import ProductTable from "./productTable";
import MaterialTable from "./materialTable";
import Select from "@/components/Select";

export default defineComponent({
    props: ["materialBindingRow"],
    emits: ["update:materialBindingRow"],
    components: {
        ProductTable,
        MaterialTable,
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            formRef: null,
            loading: false,
            visible: false,
            productInputOnblur: false,
            productInputRef: null,
            materialInputOnblur: false,
            materialInputRef: null,
            tableData: [],
            value: "",
            processOptions: [],
            formData: {
                cjWorkOrderStepId: "",
                cjProductBarcode: "",
                materialBarCode: "",
                materialBarCodes: [],
            },
        });

        const handleCancel = () => {
            state.formData.cjProductBarcode = "";
            state.formData.materialBarCode = "";
            emit("update:materialBindingRow");
        };

        const getDetailData = async row => {
            let res = await apiCjWorkOrderMaterialBindIndexData(row?.id);
            if (res.status === "SUCCESS") {
                state.processOptions = res?.data?.steps;
            }
        };

        const handleScanProduct = () => {
            state.productInputOnblur = true;
            state.productInputRef.focus();
            state.formData.cjProductBarcode = "";
        };

        const handleDeleteFinishProductTableData = () => {
            state.disableFinishProductData = [];
            state.formData.cjProductBarcode = "";
        };

        const onChangeMaterialTable = data => {
            state.formData.materialBarCodes = data.map(item => item.itemBarcode);
        };

        const handleScanMaterial = () => {
            state.materialInputOnblur = true;
            state.materialInputRef.focus();
            state.formData.materialBarCode = "";
        };

        const handleSubmit = async () => {
            if (!state.formData.cjWorkOrderStepId) {
                return showMessage("info", "请选择工序");
            }
            if (!state.formData.cjProductBarcode) {
                return showMessage("info", "请扫描产成品条码");
            }
            if (state.formData.materialBarCodes.length == 0) {
                return showMessage("info", "请扫描物料条码");
            }
            let res = await apiSaveMaterial(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
            }
        };

        watch(
            () => props.materialBindingRow,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleSubmit,
            handleScanProduct,
            handleDeleteFinishProductTableData,
            handleScanMaterial,
            onChangeMaterialTable,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hideinput {
    width: 80%;
    position: absolute;
    left: 0;
    z-index: -1;
    opacity: 0;
}
.btn {
    position: absolute;
    top: 0;
    right: 40px;
    &.stop {
        background: #67c23a;
        border-color: #67c23a;
    }
}
</style>
