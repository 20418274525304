<template>
    <div style="margin-bottom: 15px">产品信息</div>
    <a-table
        style="margin-bottom: 15px"
        :columns="columns"
        :data-source="tableData"
        bordered
        size="small"
        :pagination="false"
        :scroll="{ x: 1500 }"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a-space direction="horizontal" :size="5">
                    <a @click="handleDelete">删除</a>
                </a-space>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, watchEffect } from "vue";
import { apiCjFinishProductDetailByWorkordrIdAndBarCode } from "@/api";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "产品编码",
        dataIndex: "productCode",
    },
    {
        title: "产品名称",
        dataIndex: "productName",
    },
    {
        title: "规格型号",
        dataIndex: "spec",
    },
    {
        title: "产品条码",
        dataIndex: "barcode",
    },
    {
        title: "生产批次号",
        dataIndex: "productionBatchNo",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];

export default defineComponent({
    props: {
        cjProductBarcode: {
            default: "",
        },
        materialBindingRow: {
            default: null,
        },
    },
    emits: ["delete", "succcess"],
    setup(props, { emit }) {
        const state = reactive({
            tableData: [],
        });

        const getCjFinishProductDetail = async () => {
            let res = await apiCjFinishProductDetailByWorkordrIdAndBarCode(
                props?.materialBindingRow?.id,
                props.cjProductBarcode
            );
            if (res.status === "SUCCESS" && res?.data) {
                state.tableData = [res?.data];
                emit("succcess");
            } else {
                showMessage("info", "该工单下未找到此产品");
                emit("delete");
            }
        };

        const handleDelete = () => {
            state.tableData = [];
            emit("delete");
        };

        const antiShake = (val, time) => {
            return setTimeout(() => {
                if (val) {
                    getCjFinishProductDetail(val);
                } else {
                    state.tableData = [];
                }
            }, time);
        };

        watchEffect(onInvalidate => {
            const timer = antiShake(props.cjProductBarcode, 500);
            onInvalidate(() => clearTimeout(timer));
        });

        watch(
            () => props.materialBindingRow,
            row => {
                if (!row) {
                    state.tableData = [];
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleDelete,
        };
    },
});
</script>
