<template>
    <div style="margin-bottom: 15px">绑定物料列表</div>
    <a-table
        style="margin-bottom: 15px"
        :columns="columns"
        :data-source="tableData"
        bordered
        size="small"
        :pagination="false"
        :scroll="{ x: 1500 }"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'operate'">
                <a-space direction="horizontal" :size="5">
                    <a @click="() => handleDelete(index)">删除</a>
                </a-space>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs, watch, watchEffect } from "vue";
import { apiMaterialDetail } from "@/api";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "物料编码",
        dataIndex: "itemCode",
    },
    {
        title: "物料名称",
        dataIndex: "itemName",
    },
    {
        title: "规格型号",
        dataIndex: "itemSpec",
    },
    {
        title: "物料条码",
        dataIndex: "itemBarcode",
    },
    {
        title: "物料厂商",
        dataIndex: "supplier",
    },
    {
        title: "来料批次号",
        dataIndex: "batchNo",
    },
    {
        title: "操作",
        dataIndex: "operate",
    },
];

export default defineComponent({
    props: {
        materialBarCode: {
            default: "",
        },
        materialBindingRow: {
            default: null,
        },
    },
    emits: ["changeMaterialTable", "succcess"],
    setup(props, { emit }) {
        const state = reactive({
            tableData: [],
        });

        const getMaterialDetail = async () => {
            let res = await apiMaterialDetail(props.materialBarCode);
            if (res.status === "SUCCESS" && res?.data) {
                state.tableData.push(res?.data);
                emit("changeMaterialTable", state.tableData);
            } else {
                showMessage("info", "未查询到此物料信息");
            }
        };

        const handleDelete = index => {
            state.tableData.splice(index, 1);
            emit("changeMaterialTable", state.tableData);
        };

        const antiShake = (val, time) => {
            return setTimeout(() => {
                if (val) {
                    getMaterialDetail(val);
                    emit("succcess");
                }
            }, time);
        };

        watchEffect(onInvalidate => {
            const timer = antiShake(props.materialBarCode, 500);
            onInvalidate(() => clearTimeout(timer));
        });

        watch(
            () => props.materialBindingRow,
            row => {
                if (!row) {
                    state.tableData = [];
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleDelete,
        };
    },
});
</script>
