<template>
    <a-drawer
        v-model:visible="visible"
        title="工单详情"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-tabs v-model:value="activeTabKey">
            <a-tab-pane :key="1" tab="基础信息">
                <BaseInfo :baseInfo="baseInfo" />
            </a-tab-pane>
            <a-tab-pane :key="2" tab="工艺信息">
                <RouteInfo :data="tableData" :routeInfo="routeInfo" />
            </a-tab-pane>
            <a-tab-pane :key="3" tab="在制品列表">
                <ProductList :cjWorkOrderId="baseInfo?.id" />
            </a-tab-pane>
            <a-tab-pane :key="4" tab="报工记录">
                <WorkReportRecord :workOrderCode="baseInfo?.code" />
            </a-tab-pane>
            <a-tab-pane :key="5" tab="异常记录">
                <AbnormalRecord :data="abnormalData" />
            </a-tab-pane>
            <a-tab-pane :key="6" tab="操作记录">
                <OperateRecord :data="operateData" />
            </a-tab-pane>
        </a-tabs>

        <div class="desc"></div>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs, computed } from "vue";
import { apiCJWorkOrderDetail } from "@/api";
import BaseInfo from "./baseInfo";
import RouteInfo from "./routeInfo";
import ProductList from "./productList";
import AbnormalRecord from "./abnormalRecord";
import WorkReportRecord from "./workReportRecord";
import OperateRecord from "./operateRecord";
export default defineComponent({
    props: ["detailId", "tabStatus"],
    emits: ["update:detailId"],
    components: {
        BaseInfo,
        ProductList,
        RouteInfo,
        AbnormalRecord,
        WorkReportRecord,
        OperateRecord,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            activeTabKey: 1,
            detailId: null,
            routeInfo: null,
            baseInfo: null,
            tableData: [],
            abnormalData: [],
            operateData: [],
        });

        const handleCancel = () => {
            emit("update:detailId");
        };

        const getDetailData = async id => {
            let res = await apiCJWorkOrderDetail(id);
            if (res.status === "SUCCESS") {
                const { processJson, steps, processFiles, mesExcReportInfos, operates } = res?.data;
                state.baseInfo = res.data;
                state.routeInfo = {
                    ...processJson,
                    processFiles,
                };
                state.tableData = steps;
                state.abnormalData = mesExcReportInfos;
                state.operateData = operates;
            }
        };

        watch(
            () => props.detailId,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getDetailData(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
