<template>
    <a-modal
        v-model:visible="visible"
        title="打印条码"
        @cancel="handleCancel"
        :destroyOnClose="true"
        width="830px"
        :footer="null"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            :validateTrigger="['onBlur', 'change']"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="选择打印机"
                name="unit"
                :rules="[{ required: true, message: '请选择选择打印机' }]"
            >
                <a-select v-model:value="formData.value1">
                    <a-select-option value="lucy">Lucy</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item
                label="选择条码类型"
                name="unit"
                :rules="[{ required: true, message: '请选择条码类型' }]"
            >
                <a-select v-model:value="formData.value2">
                    <a-select-option value="1">条形码</a-select-option>
                    <a-select-option value="2">二维码</a-select-option>
                </a-select>
            </a-form-item>

            <div style="margin-bottom: 10px">打印数据列表</div>

            <a-table
                :columns="columns"
                :data-source="printList"
                bordered
                size="small"
                style="margin-bottom: 10px"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index }}
                    </template>
                </template>
            </a-table>
            <div>打印数量合计：{{ printList?.length }} 张</div>
            <a-form-item :wrapper-col="{ offset: 19, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">开始打印</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiWorkOrderGetReportInfo, apiWorkOrderSaveReport } from "@/api";
import Select from "@/components/Select";
import { showMessage, checkValueIsAvailable } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import Upload from "@/components/Upload";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 80,
    },
    {
        title: "条码",
        dataIndex: "barcode",
    },
];

export default defineComponent({
    props: ["showPrintVisible", "printList"],
    emits: ["eventUpdateOk", "update:showPrintVisible"],
    components: {
        Select,
        DownOutlined,
        Upload,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            selectedRowKeys: [],
            selectedRows: [],
            formData: {
                value1: "",
                value2: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:showPrintVisible", null);
        };

        const onFinish = async () => {};

        watch(
            () => props.showPrintVisible,
            newVal => {
                state.visible = newVal;
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            columns,
        };
    },
});
</script>

<style lang="less" scoped>
.report-process-desc {
    color: grey;
    > div {
        margin-top: 16px;
        display: flex;
        > div {
            flex: 1;
        }
    }
}
</style>
